export const getRandomNumber = () =>
  parseInt(Math.random().toString().split('.')[1], 10);

export const getRandomIntInclusive = (minNumber, maxNumber) => {
  const min = Math.ceil(minNumber);
  const max = Math.floor(maxNumber);
  return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
};

export const range = (start, stop, step = 1) => {
  const move = stop < start ? -step : step;
  return Array.from(
    { length: (stop - start) / move + 1 },
    (_, i) => start + i * move
  );
};
